import { includes, isArray, range } from 'lodash';

const ContinuousPricingNewBusinessChangeWindowInDays = 15;

export const RIGHT_TRACK = '100048';
export const BY_MILE = '103902';
export const RIGHTTRACK_TAG = '101727';
export const RIGHTTRACK_LEGACY_MOBILE = '101705';
export const RIGHTTRACK_MOBILE = '104502';
export const RIGHTTRACK_NEXTGEN = '106700';
export const RIGHTTRACK_PLUS = '101100';
export const RIGHTTRACK_CONTINUOUS_PRICING = '109700';

export const OUT = 'Opt-Out';
export const IN = 'Opt-In';
export const FINAL = 'Final';
export const INELIGIBLE = 'Ineligible';

const finalDiscounts = () =>
  range(40, 66).reduce(
    (p, c) => ({
      ...p,
      [c]: [`RightTrack Final Discount: ${c - 35}%`, FINAL],
    }),
    {},
  );

const legacyFinalDiscounts = () =>
  range(105, 130).reduce(
    (p, c) => ({
      ...p,
      [c]: [`RightTrack Final Discount: ${c - 100}%`, FINAL],
    }),
    {},
  );

// 1-30% surcharge 31+ not included
const finalSurcharges = () =>
  range(144, 174).reduce(
    (p, c) => ({
      ...p,
      [c]: [`RightTrack Final Surcharge: ${c - 143}%`, FINAL],
    }),
    {},
  );

export const RIGHTTRACK_NEXTGEN_DROPDOWN_VALUES = {
  optInCodeList: [137],
  keepInitialDiscountCodeList: [29],
  finalDiscountCodeList: [138, 133, 134, 135, 136, ...range(40, 66)],
  optOutCodeList: [21, 22, 24, 25, 27, 36, 37],
  finalSurchargeCodeList: range(144, 174),
};

export const RIGHTTRACK_MOBILE_DROPDOWN_VALUES = {
  keepInitialDiscountCodeList: [29],
  optOutCodeList: [21, 22, 24, 25, 27, 36, 37],
};

export const RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES = {
  NewBusinessWindow: 4,
  NonNewBusinessWindow: 5,
  ReEnroll: 6,
  OptOutCodeList: [22, 24, 27, 36, 37],
  SuperuserOptInCodeList: [4, 5, 6],
  NotParticipating: 99,
  Ineligible: 98,
};

export const statusCodes: Record<string, Record<string | number, string | string[] | string[][]>> = {
  [RIGHTTRACK_NEXTGEN]: {
    21: ['Opt Out - Moved', OUT],
    22: ['Opt Out - Leaving Liberty Mutual', OUT],
    24: ['Opt Out - Privacy Concerns', OUT],
    25: ['Opt Out - Device Problems', OUT],
    27: ['Opt Out - Other', OUT],
    29: ['Opt Out - Keep Initial 10% Discount', OUT],
    30: ['Opt Out - Did not complete T&C requirement', OUT],
    31: ['Opt Out - Did not Initiate Data Transmission', OUT],
    32: ['Opt Out - Phone Incompatible', OUT],
    36: ['Opt Out - Technology Issue', OUT],
    37: ['Opt Out - Customer Request', OUT],

    98: 'Ineligible',
    99: ['Not Participating', OUT],
    137: ['Opt In - RightTrack 10%', IN],
    138: ['RightTrack Final Discount: 0%', FINAL],
    133: ['RightTrack Final Discount: 1%', FINAL],
    134: ['RightTrack Final Discount: 2%', FINAL],
    135: ['RightTrack Final Discount: 3%', FINAL],
    136: ['RightTrack Final Discount: 4%', FINAL],

    ...finalDiscounts(),
    ...finalSurcharges(),
  },
  [RIGHTTRACK_MOBILE]: {
    21: ['Opt Out - Moved', OUT],
    22: ['Opt Out - Leaving Liberty Mutual', OUT],
    24: ['Opt Out - Privacy Concerns', OUT],
    25: ['Opt Out - Device Problems', OUT],
    27: ['Opt Out - Other', OUT],
    29: ['Opt Out - Keep Initial 10% Discount', OUT],
    30: ['Opt Out - Did not complete T&C requirement', OUT],
    31: ['Opt Out - Did not Initiate Data Transmission', OUT],
    32: ['Opt Out - Phone Incompatible', OUT],
    36: ['Opt Out - Technology Issue', OUT],
    37: ['Opt Out - Customer Request', OUT],

    98: 'Ineligible',
    99: ['Not Participating', OUT],
    137: ['Opt In - RightTrack 10%', IN],
    138: ['RightTrack Final Discount: 0%', FINAL],
    133: ['RightTrack Final Discount: 1%', FINAL],
    134: ['RightTrack Final Discount: 2%', FINAL],
    135: ['RightTrack Final Discount: 3%', FINAL],
    136: ['RightTrack Final Discount: 4%', FINAL],

    ...finalDiscounts(),
  },

  [BY_MILE]: {
    10: ['Opt In - ByMile', IN],
    98: 'Ineligible',
    99: ['Not Participating', OUT],
    137: ['Opt In - RightTrack 10%', IN],
    24: ['Opt Out - Privacy Concerns', OUT],
    30: ['Opt Out - Did not complete T&C requirement', OUT],
    31: ['Opt Out - Did not Initiate Data Transmission', OUT],
    36: ['Opt Out - Technology Issue', OUT],
    37: ['Opt Out - Customer Request', OUT],
  },

  [RIGHTTRACK_TAG]: {
    25: ['Opt Out - Device Problem', OUT],
    27: ['Opt Out - Other', OUT],
    28: ['Opt Out - Keep Initial 5% Discount', FINAL],
    30: ['Opt Out - Did not complete T&C requirement', OUT],
    31: ['Opt Out - Did not Initiate Data Transmission', OUT],
    34: ['Opt Out - Account Not Registered', OUT],
    35: ['Opt Out - No Activity Detected', OUT],

    98: 'Ineligible',
    99: ['Not Participating', OUT],
    137: ['Opt In - RightTrack 10%', IN],

    ...finalDiscounts(),
  },

  [RIGHT_TRACK]: {
    13: ['Opt In - RightTrack 5%', IN],
    25: ['Opt Out - Device Problem', OUT],
    27: ['Opt Out - Other', OUT],
    28: ['Opt Out - Keep Initial 5% Discount', FINAL],
    30: ['Opt Out - Did not complete T&C requirement', OUT],
    31: ['Opt Out - Did not Initiate Data Transmission', OUT],
    34: ['Opt Out - Account Not Registered', OUT],
    35: ['Opt Out - No Activity Detected', OUT],

    98: 'Ineligible',
    99: ['Not Participating', OUT],
    137: ['Opt In - RightTrack 10%', IN],
    143: ['Opt In - RightTrack 15%', IN],

    ...finalDiscounts(),
  },

  [RIGHTTRACK_PLUS]: {
    101: ['Opt In - RightTrack: 5% - iPhone 5 or newer', IN],
    102: ['Opt In - RightTrack: 5% - Android 4.0 or newer', IN],
    103: ['Opt In - RightTrack: 5% - Older Phone or None', IN],
    20: ['Opt Out - Disposal of Vehicle', OUT],
    21: ['Opt Out - Moved', OUT],
    22: ['Opt Out - Leaving Liberty Mutual', OUT],
    24: ['Opt Out - Privacy Concerns', OUT],
    25: ['Opt Out - Device Problem', OUT],
    26: ['Opt Out - Vehicle Incompatible', OUT],
    27: ['Opt Out - Other', OUT],
    28: ['Opt Out - Keep Initial 5% Discount', FINAL],
    30: ['Opt Out - Did not complete T&C requirement', OUT],
    31: ['Opt Out - Did not Initiate Data Transmission', OUT],

    98: 'Ineligible',
    99: ['Not Participating', OUT],

    ...legacyFinalDiscounts(),
  },
  [RIGHTTRACK_LEGACY_MOBILE]: {
    131: ['Opt In - RightTrack: 5% - iPhone 4S or later/Android 4.0 or later', IN],
    20: ['Opt Out - Disposal of Vehicle', OUT],
    21: ['Opt Out - Moved', OUT],
    22: ['Opt Out - Leaving Liberty Mutual', OUT],
    24: ['Opt Out - Privacy Concerns', OUT],
    25: ['Opt Out - Device Problem', OUT],
    26: ['Opt Out - Vehicle Incompatible', OUT],
    27: ['Opt Out - Other', OUT],
    28: ['Opt Out - Keep Initial 5% Discount', FINAL],
    30: ['Opt Out - Did not complete T&C requirement', OUT],
    31: ['Opt Out - Did not Initiate Data Transmission', OUT],

    98: 'Ineligible',
    99: ['Not Participating', OUT],
    ...legacyFinalDiscounts(),
    133: ['RightTrack Final Discount: 1%', FINAL],
    134: ['RightTrack Final Discount: 2%', FINAL],
    135: ['RightTrack Final Discount: 3%', FINAL],
    136: ['RightTrack Final Discount: 4%', FINAL],
  },
  //The inner array is used for alternative text for non-superusers vs superusers(needs to distinguish between the 4, 5, 6 code texts)
  [RIGHTTRACK_CONTINUOUS_PRICING]: {
    4: [
      ['Participating', IN],
      ['Opt In - New Business Window', IN],
    ],
    5: [
      ['Participating', IN],
      ['Opt In - Non New Business Window', IN],
    ],
    6: [
      ['Participating', IN],
      ['Opt In - Re-Enroll', IN],
    ],
    22: ['Opt Out - Leaving Liberty Mutual', OUT],
    24: ['Opt Out - Privacy Concerns', OUT],
    27: ['Opt Out - Other', OUT],
    30: ['Opt Out - Did not complete T&C requirement', OUT],
    31: ['Opt Out - Did not Initiate Data Transmission', OUT],
    32: ['Opt Out - Phone Incompatible', OUT],
    35: ['Opt Out - No Activity Detected', OUT],
    36: ['Opt Out - Technology Issue', OUT],
    37: ['Opt Out - Customer Request', OUT],
    244: ['Opt Out - Re-enroll Insufficient Data', OUT],
    98: ['Ineligible', INELIGIBLE],
    99: ['Not Participating', OUT],
  },
};

const programCode: Record<string, string> = {
  [RIGHT_TRACK]: 'RightTrack',
  [BY_MILE]: 'ByMile',
  [RIGHTTRACK_TAG]: 'RightTrack',
  [RIGHTTRACK_MOBILE]: 'RightTrack Mobile',
  [RIGHTTRACK_NEXTGEN]: 'RightTrack Rating Program',
  [RIGHTTRACK_PLUS]: 'RightTrack',
  [RIGHTTRACK_LEGACY_MOBILE]: 'RightTrack',
};

const participatingCode: Record<string, Record<string, string | string[]>> = {
  [RIGHTTRACK_NEXTGEN]: { false: '99', true: '137' },
  [RIGHTTRACK_MOBILE]: { false: '99', true: '137' },
  [RIGHTTRACK_TAG]: { false: '99', true: '137' },
  [RIGHT_TRACK]: { false: '99', true: ['13', '137', '143'] },
  [RIGHTTRACK_PLUS]: { false: '99', true: ['101', '102', '103'] },
  [BY_MILE]: { false: '99', true: '10' },
  [RIGHTTRACK_LEGACY_MOBILE]: { false: '99', true: '131' },
  [RIGHTTRACK_CONTINUOUS_PRICING]: { false: '99', true: ['4', '5', '6'] },
};

const immutableStatusCodes: Record<string, string[]> = {
  [RIGHTTRACK_NEXTGEN]: ['98'],
  [RIGHTTRACK_MOBILE]: ['98'],
};

const programPriority: Record<string, number> = {
  [RIGHTTRACK_CONTINUOUS_PRICING]: 0,
  [RIGHTTRACK_NEXTGEN]: 0,
  [RIGHTTRACK_MOBILE]: 0,
  [BY_MILE]: 1,
};

const programConfig: Record<string, Record<string, boolean>> = {
  [RIGHTTRACK_NEXTGEN]: {
    OperatorProduct: true,
    VehicleProduct: false,
    Description: true,
    NextSteps: true,
    ParticipationLabel: true,
    EligiblePhoneLabel: true,
    RedBorder: true,
  },
  [RIGHTTRACK_MOBILE]: {
    OperatorProduct: true,
    VehicleProduct: false,
    Description: true,
    NextSteps: true,
    ParticipationLabel: true,
    EligiblePhoneLabel: true,
    RedBorder: true,
  },
  [RIGHTTRACK_TAG]: {
    OperatorProduct: false,
    VehicleProduct: true,
    Description: false,
    NextSteps: false,
    ParticipationLabel: false,
    EligiblePhoneLabel: false,
    LegacyProduct: true,
  },
  [RIGHT_TRACK]: {
    OperatorProduct: false,
    VehicleProduct: true,
    Description: false,
    NextSteps: false,
    ParticipationLabel: false,
    EligiblePhoneLabel: false,
    LegacyProduct: true,
  },
  [RIGHTTRACK_PLUS]: {
    OperatorProduct: false,
    VehicleProduct: true,
    Description: false,
    NextSteps: false,
    ParticipationLabel: false,
    EligiblePhoneLabel: false,
    LegacyProduct: true,
  },
  [BY_MILE]: {
    OperatorProduct: false,
    VehicleProduct: true,
    Description: true,
    NextSteps: true,
    ParticipationLabel: false,
    EligiblePhoneLabel: false,
  },
  [RIGHTTRACK_LEGACY_MOBILE]: {
    OperatorProduct: false,
    VehicleProduct: true,
    Description: false,
    NextSteps: false,
    ParticipationLabel: false,
    EligiblePhoneLabel: false,
    LegacyProduct: true,
  },
  [RIGHTTRACK_CONTINUOUS_PRICING]: {
    OperatorProduct: true,
    VehicleProduct: false,
    Description: false,
    NextSteps: false,
    ParticipationLabel: false,
    EligiblePhoneLabel: false,
    LegacyProduct: false,
  },
};

const getCode = (label: string, object: Record<string | number, string | string[] | string[][]>): string =>
  Object.entries(object)
    .filter(([, value]) => (Array.isArray(value) ? value[0] : value) === label)
    .reduce((p, [key]) => key, '');

export const getContinuousPricingDropdownCodes = (
  daysBetweenDates: number,
  priorPolicyTelematicsStatus: string,
  anyOtherOptInNewBusiness: boolean,
  isRTAdmin: boolean,
  currentTelematicsStatus: string
): number[] => {
  const targetParticipationCode: number[] = [];

  if (isRTAdmin) {
    targetParticipationCode.push(RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.NewBusinessWindow);
    targetParticipationCode.push(RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.NonNewBusinessWindow);
    //only allow the re-enroll status if the current status is an opt out
    if (!isOptOutNotParticipating(currentTelematicsStatus) && isOptOutCode(RIGHTTRACK_CONTINUOUS_PRICING, currentTelematicsStatus)) {
      targetParticipationCode.push(RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.ReEnroll);
    }
    //if the prior status is blank it must be a new enrollment, only allow Opt Ins and Not Participating
    if ('' === priorPolicyTelematicsStatus) {
      targetParticipationCode.push(Number(getParticipatingCode(RIGHTTRACK_CONTINUOUS_PRICING, false)));
      //don't allow opt outs when the current status is Not Participating
    } else if (!isOptOutNotParticipating(currentTelematicsStatus)) {
      targetParticipationCode.push(...RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.OptOutCodeList);
    }

  } else {
    // if the prior policy telematics status is an opt in code, set the list equal to the opt out codes
    // user should be able to re-select their initial opt in code
    if (priorPolicyTelematicsStatus && isOptInCode(RIGHTTRACK_CONTINUOUS_PRICING, priorPolicyTelematicsStatus)) {
      targetParticipationCode.length = 0;
      targetParticipationCode.push(Number(priorPolicyTelematicsStatus));
      targetParticipationCode.push(...RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.OptOutCodeList);
    } // if the current telematics status is an opt out code, and it is not a new enrollment
    // set the list to Participating (code 6 for re-enroll)
    // user should be able to re-select their initial opt out code
    else if (
      priorPolicyTelematicsStatus &&
      !isOptOutNotParticipating(priorPolicyTelematicsStatus) &&
      isOptOutCode(RIGHTTRACK_CONTINUOUS_PRICING, priorPolicyTelematicsStatus)
    ) {
      targetParticipationCode.length = 0;
      targetParticipationCode.push(Number(priorPolicyTelematicsStatus));
      targetParticipationCode.push(RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.ReEnroll);
    } // if the current telematics status is not an Opt in or Opt out (new enrollment), set the list to include Participating (code 4 or 5) and Not Participating
    else {
      targetParticipationCode.length = 0;
      targetParticipationCode.push(getNewBusinessWindowParticipatingCode(daysBetweenDates, anyOtherOptInNewBusiness));
      targetParticipationCode.push(Number(getParticipatingCode(RIGHTTRACK_CONTINUOUS_PRICING, false)));
    }
  }
  return targetParticipationCode;
};

export const getProgramLabel = (code: string) => programCode[code] || code;

export const getStatusCode = (program: string, label: string) => getCode(label, statusCodes[program] || {});

export const getStatusLabel = (program: string, code: string | number, isRTAdmin: boolean) => {
  if (statusCodes[program]) {
    //check if status codes have status code types(IN, OUT, FINAL)
    if (Array.isArray(statusCodes[program][code])) {
      //check if the status code has alternative text (for RTCP with superuser vs non-superuser access)
      if (Array.isArray(statusCodes[program][code][0]) && program === RIGHTTRACK_CONTINUOUS_PRICING) {
        if (isRTAdmin) {
          return statusCodes[program][code][1][0];
        }
        return statusCodes[program][code][0][0];
      }
      // If the status code does not have status code types, return the string as is. (Ineligible)
      return statusCodes[program][code][0];
    }
    return statusCodes[program][code] || '';
  }
  return '';
};

export const getParticipatingCode = (program: string, isParticipating: boolean): string => {
  const code = participatingCode[program] && participatingCode[program][isParticipating ? 'true' : 'false'];
  return isArray(code) ? code.at(0) ?? '' : code ?? '';
};

export const getStatusCodeType = (program: string, code: string): string => {
  const type = (statusCodes[program] && Array.isArray(statusCodes[program][code]) && statusCodes[program][code][1]) || '';
  console.info('getStatusCodeType', { program, code }, type);
  if (Array.isArray(type)) {
    return type[1];
  }
  return type;
};

export const isOptInCode = (program: string, code: string) => getStatusCodeType(program, code) === IN || false;

export const isOptOutCode = (program: string, code: string) => getStatusCodeType(program, code) === OUT || false;

export const isIneligibleCode = (program: string, code: string) => getStatusCodeType(program, code) === INELIGIBLE || false;

export const isFinalDiscountOrSurchargeCode = (program: string, code: string) => getStatusCodeType(program, code) === FINAL || false;

export const isOptOutNotParticipating = (code: string) => code === '99';

export const isOptOutKeepFinalDiscount = (program: string, code: string) =>
  (program === RIGHTTRACK_NEXTGEN || program === RIGHTTRACK_MOBILE) && code === '29';

export const isCodeImmutable = (program: string, code: string) => {
  const codes = immutableStatusCodes[program] || [];
  const immutable = includes(codes, code);

  console.info('isCodeImmutable', { program, code }, immutable);

  return immutable;
};

export const isDefaultProduct = (program: string) => programPriority[program] === 0;

export const getProgramConfig = (program: string) => programConfig[program] || {};

function getNewBusinessWindowParticipatingCode(daysBetweenDates: number, anyOtherOptInNewBusiness: boolean): number {
  if (daysBetweenDates > ContinuousPricingNewBusinessChangeWindowInDays && !anyOtherOptInNewBusiness) {
    return RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.NonNewBusinessWindow;
  } else {
    return RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.NewBusinessWindow;
  }
}
